import React from 'react'

import NavigationItem from '../../components/NavigationItem'
import { OnNavigationMenuItemClick } from '../../components/NavigationItem/types'
import { getNavigationItems } from './data'

export const createLinks = (
  contactOptions?: ContactLinkOptions,
  onItemClick?: OnNavigationMenuItemClick,
): JSX.Element[] => (
  getNavigationItems(contactOptions).map((data, index) => (
    <NavigationItem
      key={`header-link-${index}`}
      href={data.href}
      text={data.text}
      className={data.className}
      external={data.external}
      scroll={data.scroll}
      onClick={onItemClick}
    />
  ))
)
