import React from 'react'

import { Svg } from './Svg'

interface Props {
  className?: string
  strokeWidth?: number
}

export const CircledCheck: React.FunctionComponent<Props> = ({ className, strokeWidth = 4 }) => (
  <Svg width={100} height={100} className={className} fill='none'>
    <path
      d='M32 55L43.5 66.5L74 36'
      stroke='currentColor'
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle
      cx='50'
      cy='50'
      r='48'
      stroke='currentColor'
      strokeWidth={strokeWidth}
    />
  </Svg>
)
