import React from 'react'
import { Link } from 'gatsby'

import ClutchWidget from '../../components/Clutch'
import Social from '../../components/Social'
import { ClutchWidgetType } from '../../enums'

import styles from './footer.module.scss'

const CLUTCH_COMPANY_ID = process.env.CLUTCH_COMPANY_ID

const shouldRenderReviews = Boolean(CLUTCH_COMPANY_ID)

interface Props {
  className?: string
}

export const FooterInfo: React.FC = () => (
  <div className={`${styles.info} grid-x grid-margin-y medium-12`}>
    <FooterLogo/>
    <Offline/>
    <Online/>
    <Social className={styles.social}/>
    {shouldRenderReviews && <Reviews/>}
  </div>
)

export const FooterLogo: React.FC<Props> = ({ className = '' }) => (
  <div className={`${className} ${styles.logo}`}>
    <Link to='/'>PINKDROIDS</Link>
  </div>
)

export const FooterLegal: React.FC<Props> = ({ className = '' }) => (
  <p className={`${className} ${styles.legalCopy} light-copy copy--xsm pink`}>
    <span><b>PINKDROIDS d.o.o.</b> za računalne i srodne djelatnosti,</span>
    <span>Strojarska cesta 20, 10000 Zagreb.</span>
    <span><b>MBS</b>: 081003515, <b>OIB</b>: 08652241354.</span>
    <span>
      <b>Nadležni sud</b>: Trgovački sud u Zagrebu. <b>Temeljni kapital</b>: 20.000,00 kuna.
    </span>
    <span><b>Osnivači/članovi društva</b>: Aljoša Mohorović, Vedran Vojvoda.</span>
  </p>
)

export const FooterSeparator: React.FC = () => <div className={`${styles.separator}`}>&nbsp;</div>

const Reviews: React.FC<Props> = ({ className = '' }) => (
  <div className={`${styles.reviews} ${className}`}>
    <ClutchWidget
      companyId={CLUTCH_COMPANY_ID}
      className={styles.clutch}
      widgetType={ClutchWidgetType.XSmall}
      height='40'
      darkbg
    />
  </div>
)

const Online: React.FC<Props> = ({ className = '' }) => (
  <div className={`${className} ${styles.online} light-copy pink`}>
    <span><strong><i>Online</i></strong></span>
    <br/><br/>
    <span><strong>email:</strong></span>
    <span>
      <a href='mailto:office@pinkdroids.com'><i>
        office@pinkdroids.com
      </i></a></span>
  </div>
)

const Offline: React.FC<Props> = ({ className = '' }) => (
  <div className={`${className} ${styles.offline} light-copy pink`}>
    <span><strong><i>Offline</i></strong></span>
    <br/>
    <span>Strojarska cesta 20</span>
    <span>10000 Zagreb</span>
    <span>+385 99 1954056</span>
  </div>
)
