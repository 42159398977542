import { NavigationMenuItem } from '../../components/NavigationItem/types'
import { getNavigationItemsWithContactButton, Options } from '../utils'

const staticNavigationItems: NavigationMenuItem[] = [
  {
    href: '/what-we-do/',
    text: 'What we do',
    external: false,
  },
  {
    href: '/who-we-are/',
    text: 'Who we are',
    external: false,
  },
  {
    href: '/legal/',
    text: 'Legal',
    external: false,
  },
]

export const getNavigationItems = (contactOptions?: Options): NavigationMenuItem[] => (
  getNavigationItemsWithContactButton(staticNavigationItems, contactOptions)
)
