import React from 'react'

import { Svg } from './Svg'

interface Props {
  className?: string
}

export const Facebook: React.FunctionComponent<Props> = ({ className }) => (
  <Svg width={40} height={40} className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40
      8.95431 31.0457 0 20
      0ZM22.0846 20.8785V31.7596H17.5826V20.8789H15.3333V17.1293H17.5826V14.878C17.5826
      11.819 18.8526 10 22.461 10H25.4651V13.7501H23.5873C22.1827 13.7501 22.0897 14.2741 22.0897
      15.2521L22.0846 17.1288H25.4863L25.0883 20.8785H22.0846Z'
      fill='currentColor'
    />
  </Svg>
)
