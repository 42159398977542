import React from 'react'
import { Link } from 'gatsby'

import Navigation from '../../components/Navigation'
import { useHeaderContext, useMenuContext } from '../../context'
import { createLinks } from './utils'

import styles from './header.module.scss'

interface Props {
  lightBg?: boolean
  contactLinkOptions?: ContactLinkOptions
}

export const Header: React.FC<Props> = ({ lightBg = false, contactLinkOptions }) => {
  const { isVisible: isMenuVisible, hide: hideMenu } = useMenuContext()
  const onLinkClick = React.useCallback(() => {
    hideMenu()
  }, [hideMenu])
  const { isVisible, isOnTopOfPage } = useHeaderContext()

  return (
    // Container is used instead of <body/> padding for scroll to top
    <div className={`${styles.headerContainer} ${!lightBg ? styles.headerContainerDark : ''}`}>
      <header className={genClassName(lightBg, isVisible, isOnTopOfPage, isMenuVisible)}>
        <div className={`container container--wide grid-x ${styles.headerContent}`}>
          <Link
            className={`${styles.headerLogo} cell large-3 small-9`} to='/'
            onClick={onLinkClick}
          >
            PINKDROIDS
          </Link>
          <Navigation
            className='cell large-9 small-3'
            items={createLinks(contactLinkOptions, onLinkClick)}
            lightBg={lightBg}
          />
        </div>
      </header>
    </div>
  )
}

const genClassName = (
  lightBg: boolean,
  isVisible: boolean,
  isOnTopOfPage: boolean,
  isMobileMenuVisible: boolean,
) => {
  const lightClass = lightBg && !isMobileMenuVisible ? styles.headerLight : ''
  const visibilityClass = !isVisible ? styles.headerInvisible : ''
  const onTopOfPageClass = isOnTopOfPage ? styles.headerTopOfPage : ''
  return `${styles.header} ${lightClass} ${visibilityClass} ${onTopOfPageClass}`
}
