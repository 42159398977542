import { NavigationMenuItem } from '../components/NavigationItem/types'
import { ContactHref } from '../enums'

export type Options = ContactLinkOptions & { className?: string, containerClassName?: string }

export const getContactButton = ({
  // if no element is provided, link to index contact is used
  element,
  href = ContactHref.IndexPage,
  className = '',
  containerClassName = '',
}: Options = {}): NavigationMenuItem => ({
  href: element || href,
  text: 'Get in touch!',
  external: false,
  scroll: Boolean(element),
  className: `btn btn--nav btn--pink ${className}`,
  containerClassName,
})

export const getNavigationItemsWithContactButton = (
  staticNavigationItems: NavigationMenuItem[],
  contactOptions?: Options,
): Array<NavigationMenuItem> => staticNavigationItems.concat(getContactButton(contactOptions))
