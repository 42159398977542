import React from 'react'
import ScrollLock from 'react-scrolllock'

import { useMenuContext } from '../../context'

import styles from './navigation.module.scss'

interface Props {
  items: JSX.Element[]
  lightBg?: boolean
  isVisible?: boolean
}

export const MobileNavMenu: React.FC<Props> = ({ items, lightBg = false }) => {
  const { isVisible, show, hide } = useMenuContext()
  const showMenu = React.useCallback(() => {
    show()
  }, [show])
  const hideMenu = React.useCallback(() => {
    hide()
  }, [hide])

  return (
    <div className={`${styles.navigationMobile} hide-for-large`}>
      <ul>
        {!isVisible && <li className={genMobileOpenClassName(lightBg)} onClick={showMenu}/>}
        {isVisible && (
          <li
            className={`${styles.navigationMobileBtn} ${styles.navigationMobileClose}`}
            onClick={hideMenu}
          />
        )}
      </ul>
      <div className={
        `${styles.navigationPanel} ${isVisible ? styles.navigationPanelVisible : ''}`
      }>
        <ScrollLock isActive={isVisible} accountForScrollbars={false}>
          <ul>{items}</ul>
        </ScrollLock>
      </div>
    </div>
  )
}

const genMobileOpenClassName = (lightBg: boolean): string =>
  `
    ${styles.navigationMobileBtn}
    ${styles.navigationMobileOpen}
    ${lightBg ? styles.navigationMobileBtnLightBg : ''}
  `
