import React from 'react'

import NavigationItem from '../../components/NavigationItem'
import { getNavigationItems } from './data'

export const createLinks = (contactOptions?: ContactLinkOptions): Array<JSX.Element> => {
  const updatedContactOptions = { ...contactOptions, containerClassName: 'show-for-large' }
  return getNavigationItems(updatedContactOptions).map((data, index) => (
    <NavigationItem
      key={`footer-link-${index}`}
      href={data.href}
      text={data.text}
      className={data.className}
      containerClassName={data.containerClassName}
      external={data.external}
      scroll={data.scroll}
    />
  ))
}
