import React from 'react'

import styles from './hamag.module.scss'

export const Hamag: React.FC = () =>
  <div className={`container ${styles.hamag}`}>
    <div className={styles.hamagCopy}>
      <p>
        Krajnji primatelj ﬁnancijskog instrumenta suﬁnanciranog iz Europskog fonda za regionalni
        razvoj u sklopu Operativnog programa „Konkurentnost i kohezija“
      </p>
    </div>
    <div className={styles.hamagLogos}>
      <div>
        <img src="/images/hamag/HAMAG-Bicro-logo-RGB-mali.jpg"/>
      </div>
      <div>
        <img src="/images/hamag/EU_amblem.jpg"/>
        <img src="/images/hamag/ESIF_FI_logo_korisnik.jpg"/>
      </div>
    </div>
  </div>
