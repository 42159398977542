import React from 'react'

import { ArrowDirection } from '../../../enums'
import { Svg } from '../Svg'

import styles from './arrow.module.scss'

const styleDirectionMapping: { [key in ArrowDirection]: string} = {
  [ArrowDirection.Down]: styles.arrowDown,
  [ArrowDirection.Left]: styles.arrowLeft,
  [ArrowDirection.Right]: styles.arrowRight,
  [ArrowDirection.Up]: styles.arrowUp,
}

interface Props {
  className?: string
  direction: ArrowDirection
}

export const Arrow: React.FC<Props> = ({ className, direction }) => (
  <Svg width={42} height={26} className={`${className} ${styleDirectionMapping[direction]}`}>
    <path
      d='M1 13L41 13M41 13L29 1M41 13L29 25'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
)
