import React from 'react'

import { Svg } from './Svg'

interface Props {
  className?: string
}

export const GitHub: React.FunctionComponent<Props> = ({ className }) => (
  <Svg width={29} height={29} className={className}>
    <path
      d='M14.5016 0.574708C6.48944 0.571495 0 7.00436 0 14.9442C0 21.2229 4.05955 26.56 9.7131
      28.5201C10.4745 28.7097 10.3578 28.1731 10.3578 27.8067V25.3165C5.96135 25.8274 5.78315
      22.9419 5.48833 22.4599C4.89219 21.451 3.48285 21.1939 3.90403 20.712C4.90515 20.2011 5.92571
      20.8405 7.10826 22.5724C7.96358 23.8288 9.63211 23.6167 10.4777 23.4079C10.6624 22.6527
      11.0576 21.978 11.6019 21.4542C7.0467 20.6445 5.14814 17.8875 5.14814 14.6101C5.14814 13.0195
      5.67624 11.5575 6.71299 10.3782C6.05206 8.43424 6.77455 6.7698 6.87175 6.52238C8.75411 6.35529
      10.711 7.85908 10.8633 7.97797C11.9324 7.69199 13.1538 7.54097 14.5211 7.54097C15.8948 7.54097
      17.1194 7.69842 18.1983 7.98761C18.5644 7.71127 20.3787 6.41956 22.1283 6.577C22.2222 6.82442
      22.9285 8.45031 22.3064 10.3686C23.3562 11.5511 23.8907 13.0259 23.8907 14.6197C23.8907
      17.9036 21.9792 20.6638 17.411 21.4606C17.8023 21.8423 18.113 22.2974 18.3249 22.7994C18.5369
      23.3015 18.6458 23.8404 18.6454 24.3847V27.9995C18.6713 28.2887 18.6454 28.5747 19.1314
      28.5747C24.8692 26.6564 29 21.2807 29 14.9474C29 7.00436 22.5073 0.574708 14.5016 0.574708Z'
      fill='currentColor'
    />
  </Svg>
)
