import React, { useEffect } from 'react'

import { ClutchWidgetType } from '../../enums'

declare global {
  interface Window {
    CLUTCHCO: {
      Init: VoidFunction,
    }
  }
}

interface Props {
  companyId?: string
  widgetType: ClutchWidgetType
  className?: string
  height?: string
  darkbg?: boolean
}

export const ClutchWidget: React.FC<Props> = ({
  companyId,
  widgetType,
  className = '',
  height = 'auto',
  darkbg = false,
}) => {
  useEffect(() => {
    if (!companyId) {
      return
    }

    const script = document.createElement('script')
    script.src = 'https://widget.clutch.co/static/js/widget.js'
    script.async = true
    script.onload = () => {
      window.CLUTCHCO.Init()
    }

    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [companyId])

  return companyId ? (
    <div className={className}>
      <div
        className='clutch-widget'
        data-url='https://widget.clutch.co'
        data-widget-type={widgetType}
        data-expandifr='false'
        data-height={height}
        data-darkbg={darkbg ? '1' : '0'}
        data-clutchcompany-id={companyId}
      />
    </div>
  ) : null
}
