import React from 'react'

import { Svg } from './Svg'

interface Props {
  className?: string
}

export const Twitter: React.FunctionComponent<Props> = ({ className }) => (
  <Svg width={40} height={40} className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40
      8.95431 31.0457 0 20 0ZM19.443 16.9483L19.401 16.2563C19.2751 14.4626 20.3803 12.8243 22.129
      12.1887C22.7725 11.9627 23.8637 11.9345 24.5772 12.1322C24.8569 12.217 25.3885 12.4994 25.7663
      12.7537L26.4518 13.2197L27.2072 12.9796C27.6269 12.8525 28.1865 12.6407 28.4383
      12.4994C28.6761 12.3723 28.8859 12.3017 28.8859 12.3441C28.8859 12.5842 28.3683 13.4033
      27.9347 13.8553C27.3471 14.4908 27.515 14.5473 28.7041 14.1236C29.4175 13.8835 29.4315 13.8835
      29.2916 14.1519C29.2077 14.2931 28.774 14.7874 28.3124 15.2394C27.529 16.0162 27.487 16.1009
      27.487 16.7506C27.487 17.7533 27.0113 19.8436 26.5357 20.9876C25.6543 23.1343 23.7658 25.3517
      21.8772 26.4674C19.2191 28.0351 15.6798 28.4306 12.7 27.5126C11.7067 27.2019 10 26.411 10
      26.2697C10 26.2274 10.5176 26.1709 11.1471 26.1567C12.4622 26.1285 13.7772 25.7613 14.8964
      25.1116L15.6518 24.6597L14.7844 24.3631C13.5534 23.9394 12.4482 22.9649 12.1684
      22.0468C12.0844 21.7502 12.1124 21.7361 12.8958 21.7361L13.7072 21.722L13.0218 21.3972C12.2104
      20.9876 11.4689 20.2955 11.1052 19.5894C10.8394 19.0809 10.5036 17.7957 10.6016
      17.6968C10.6295 17.6545 10.9233 17.7392 11.2591 17.8522C12.2243 18.2053 12.3503 18.1205
      11.7907 17.5274C10.7414 16.454 10.4197 14.858 10.9233 13.3468L11.1611 12.6689L12.0844
      13.5869C13.973 15.4371 16.1974 16.5387 18.7435 16.8636L19.443 16.9483Z'
      fill='currentColor'
    />
  </Svg>
)
