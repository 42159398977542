import React, { CSSProperties } from 'react'

import { DesktopNavMenu } from './DesktopNavMenu'
import { MobileNavMenu } from './MobileNavMenu'

import styles from './navigation.module.scss'

interface Props {
  items: Array<JSX.Element>,
  position?: 'left' | 'center' | 'right',
  mobileCollapse?: boolean,
  className?: string,
  lightBg?: boolean,
}

export const Navigation: React.FC<Props> = ({
  items,
  className = '',
  position = 'right',
  mobileCollapse = true,
  lightBg = false,
}) => (
  <div className={className}>
    <nav className={genClassName(lightBg)} style={positionToStyle(position)}>
      {mobileCollapse && <MobileNavMenu items={items} lightBg={lightBg}/>}
      <DesktopNavMenu items={items} mobileCollapse={mobileCollapse}/>
    </nav>
  </div>
)

const positionToStyle = (position: 'left' | 'center' | 'right'): CSSProperties => ({
  justifyContent: (position === 'left' && 'flex-start') ||
  (position === 'center' && 'center') ||
  (position === 'right' && 'flex-end') as string,
})

const genClassName = (lightBg: boolean): string =>
  `${styles.navigation} ${lightBg ? styles.navigationLightBg : ''}`
