import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import moment from 'moment'

import styles from './footer.module.scss'

const QUERY = graphql`
query {
  allContentfulBlogArticle (
    filter: {highlighted: {eq: true}}, 
    sort: {fields: [publishedDt], 
    order: DESC}) {
    edges {
      node {
        slug
        headline
        publishedDt
      }
    }
  }
}
`

type Post = Pick<ArticleBase, 'slug' | 'headline' | 'publishedDt'>

interface Edge {
  node: Post
}

interface QueryProps {
  allContentfulBlogArticle: {
    edges: Edge[]
  }
}

export const BlogHighlight: React.FC = () => {
  const posts = useStaticQuery<QueryProps>(QUERY).allContentfulBlogArticle.edges
    .map((edge: Edge) => edge.node)
    .map((post: Post) => <Post {...post} key={post.slug}/>)

  return (
    <div className={`${styles.blogHighlight} cell grid-x grid-margin-y`}>
      <div className={`${styles.highlightCaption} cell large-offset-1 large-5 small-12`}>
        <h5 className='pink no-margin-btm'>Good read</h5>
        <h3 className='dark-bg no-margin-btm'>
          Latest posts from <Link to='/blog/' className={styles.ourBlog}>our blog</Link>
        </h3>
      </div>
      <div className={`${styles.posts} cell large-6 small-12`}>
        {posts}
      </div>
    </div>
  )
}

const Post = ({ headline, slug, publishedDt }: Post) => (
  <div className={styles.post}>
    <p className={`${styles.date} pink lighter-copy no-margin-btm`}>
      {moment(publishedDt).format('MMM DD')}
    </p>
    <Link to={`/blog/${slug}/`} className='h5 dark-bg no-margin-btm'>
      {headline}
    </Link>
  </div>
)
