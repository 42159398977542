import React from 'react'

import styles from './footer.module.scss'

export const Illustration: React.FC = () => (
  <div className={`container ${styles.illustrationContainer}`}>
    <img
      src='/images/illustrations/footer-illustration.svg'
      alt='Planet surface illustration'
      className={styles.illustration}
    />
  </div>
)
