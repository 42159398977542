import React from 'react'

import { Svg } from './Svg'

interface Props {
  className?: string
}

export const Triangle: React.FunctionComponent<Props> = ({ className }) => (
  <Svg width={12} height={10} className={className}>
    <path
      d='M5.18484 8.85284C5.58349 9.41385 6.41651 9.41385 6.81516 8.85284L11.806 1.82924C12.2765
      1.16713 11.8031 0.25 10.9909 0.25H1.00914C0.196887 0.25 -0.276507 1.16713 0.19398
      1.82924L5.18484 8.85284Z'
      fill='currentColor'
    />
  </Svg>
)
