import React from 'react'
import { useLocation } from '@reach/router'
import trimEnd from 'lodash.trimend'
import { Helmet } from 'react-helmet'

import { useSiteMetadata } from '../../hooks'

interface Props {
  seo: SEO
  lang?: string
}

export const SEO: React.FC<Props> = ({ seo, lang = 'en' }) => {
  const siteMetadata = useSiteMetadata()

  const { siteUrl } = siteMetadata
  const metaDescription = seo.description || siteMetadata.description
  const metaTitle = seo.title || siteMetadata.title
  const image = socialImageUrl(seo.image, siteMetadata.image, siteUrl)
  const location = useLocation()
  const ogUrl = `${trimEnd(siteUrl, '/')}${location.pathname}`

  const htmlAttributes = { lang }

  const meta = [
    { name: 'description', content: metaDescription },
    { property: 'og:title', content: metaTitle },
    { property: 'og:description', content: metaDescription },
    { property: 'og:type', content: seo.type },
    { property: 'og:image', content: image },
    { property: 'og:url', content: ogUrl },
  ]

  if (process.env.FB_APP_ID) {
    meta.push({ property: 'fb:app_id', content: process.env.FB_APP_ID })
  }

  if (seo.keywords.length > 0) {
    meta.push({ name: 'keywords', content: seo.keywords.join(', ') })
  }

  return (
    <Helmet htmlAttributes={htmlAttributes} title={metaTitle} meta={meta}/>
  )
}

interface SocialImage {
  url: string
}

const socialImageUrl = (
  socialImage: SocialImage, defaultImage: string, siteUrl: string,
) => {
  const protocol = siteUrl && siteUrl.startsWith('https') ? 'https' : 'http'
  const noProtocolInImageUrl = socialImage && socialImage.url.startsWith('//')

  // Append protocol based on siteUrl if socialImage starts with "//"
  if (socialImage && noProtocolInImageUrl) {
    return `${protocol}:${socialImage.url}`
  } else if (socialImage) {
    return socialImage.url
  }
  return `${siteUrl}${defaultImage}`
}
