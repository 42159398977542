import React from 'react'

import styles from './separator.module.scss'

interface Props {
  className?: string
}

export const Separator: React.FC<Props> = ({ className }) => (
  <div className={`${styles.separator} ${className}`}>&nbsp;</div>
)
