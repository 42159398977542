import React from 'react'
import { Link as ScrollLink } from 'react-scroll'

import Navigation from '../../components/Navigation'
import { renderLink } from '../../components/NavigationItem'
import Separator from '../../components/Separator'
import { getContactButton } from '../utils'
import { BlogHighlight } from './BlogHighlight'
import { FooterInfo, FooterLegal } from './helpers'
import { Hamag } from './Hamag'
import { Illustration } from './Illustration'
import { createLinks } from './utils'

import styles from './footer.module.scss'

interface Props {
  contactLinkOptions?: ContactLinkOptions
}

export const Footer: React.FunctionComponent<Props> = ({ contactLinkOptions }) => (
  <>
    <Illustration/>
    <footer className={styles.footer}>
      <div className='container grid-x'>
        <div style={{ marginTop: '100px', lineHeight: '0' }} className='cell large-12'>
          &nbsp;
        </div>
        <div className={`${styles.navigationContainer} container grid-x`}>
          <Navigation
            items={createLinks(contactLinkOptions)}
            className={`${styles.navigation} cell large-12`}
            position='center'
            mobileCollapse={false}
          />
          <nav className={`${styles.mobileContact} hide-for-large small-12`}>
            {renderLink(getContactButton(contactLinkOptions))}
          </nav>
        </div>
        <Separator/>
        <BlogHighlight/>
        <Separator/>
        <FooterInfo/>
        <Separator/>
        <Hamag/>
        <Separator/>
        <div className={`${styles.footerBottom}`}>
          <FooterLegal/>
        </div>
      </div>
      <div className={styles.backToTopContainer}>
        <ScrollLink to='top-of-page' smooth={true} className={styles.backToTop}>
          <img
            src='/images/arrow-up.svg'
            alt='Scroll back to top'
            className={styles.backToTopArrow}
          />
          <span className='h5 secondary-pink'>BACK TO TOP</span>
        </ScrollLink>
      </div>
    </footer>
  </>
)
