import React from 'react'
import { Link } from 'gatsby'
import { Events, Link as ScrollLink } from 'react-scroll'

import { NavigationMenuItem, OnNavigationMenuItemClick } from './types'

import styles from './navigation-item.module.scss'

type Props = NavigationMenuItem & { onClick?: OnNavigationMenuItemClick }

export const NavigationItem: React.FunctionComponent<Props> = ({
  href,
  text,
  external,
  scroll = false,
  className,
  containerClassName = '',
  onClick,
}) => (
  <li className={`${styles.navigationItem} ${containerClassName}`}>
    {renderLink({ href, text, external, scroll, className, onClick })}
  </li>
)

export const renderLink = ({
  href,
  text,
  external,
  scroll,
  className,
  onClick,
}: Omit<Props, 'containerClassName'>): React.ReactNode => {
  if (external) {
    return (
      <a href={href} target='_blank' rel='noopener noreferrer' onClick={onClick}>
        {text}
      </a>
    )
  } else if (scroll) {
    Events.scrollEvent.register('begin', () => {
      if (onClick) {
        onClick()
      }
      Events.scrollEvent.remove('begin')
    })
    return (
      <ScrollLink to={href} className={className} smooth={true}>
        { text }
      </ScrollLink>
    )
  }
  return (
    <Link to={href} activeClassName='active' className={className} onClick={onClick}>
      {text}
    </Link>
  )
}
