import React from 'react'

import { Facebook, GitHub, LinkedIn } from '../icons'

import styles from './social.module.scss'

interface Props {
  className?: string
}

export const Social: React.FC<Props> = ({ className = '' }) => (
  <div className={className}>
    <ul className={`${styles.social}`}>
      {footerMediaItems.map((i, idx) =>
        <li key={idx} className='footer-navigation__item'>
          <a
            href={i.href}
            className={`media-icon ${styles.socialIconContainer}`}
            target='_blank'
            rel='noopener noreferrer'
            aria-label={i.mediaName}
          >
            {i.icon}
          </a>
        </li>,
      )}
    </ul>
  </div>
)

const footerMediaItems = [
  {
    href: 'https://www.facebook.com/pinkdroids/',
    mediaName: 'facebook',
    icon: <Facebook className={styles.socialIcon}/>,
  },
  {
    href: 'https://www.linkedin.com/company/pinkdroids/',
    mediaName: 'linkedin',
    icon: <LinkedIn className={styles.socialIcon}/>,
  },
  {
    href: 'https://github.com/pinkdroids',
    mediaName: 'github',
    icon: <GitHub className={styles.socialIcon}/>,
  },
]
