import React, { FunctionComponent } from 'react'
import { Element } from 'react-scroll'

import Content from '../Content'
import Footer from '../Footer'
import Header from '../Header'
import SEO from '../SEO'

import '../../scss/main.global.scss'

interface Props {
  pageContext: PageContext
  lightHeader?: boolean
}

export const Layout: FunctionComponent<Props> = ({
  children,
  pageContext,
  lightHeader = false,
}) => (
  <>
    <SEO seo={pageContext.seo}/>
    <Element name='top-of-page'/>
    <Header lightBg={lightHeader} contactLinkOptions={pageContext.contactLinkOptions}/>
    <Content>
      {children}
    </Content>
    <Footer contactLinkOptions={pageContext.contactLinkOptions}/>
  </>
)
