import { graphql } from 'gatsby'
import { SEO } from './SEO'

export default SEO

export const query = graphql`
  fragment SEOFragment on ContentfulSeo {
    title
    description {
      description
    }
    type
    image {
      ... SEOImageFragment
    }
    keywords
  }
`
