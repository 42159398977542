import React from 'react'

interface Props {
  items: Array<JSX.Element>
  mobileCollapse: boolean
}

export const DesktopNavMenu: React.FC<Props> = ({ items, mobileCollapse }) =>
  <ul className={`grid-x grid-margin-y ${mobileCollapse ? 'show-for-large' : ''}`}>
    {items}
  </ul>

DesktopNavMenu.defaultProps = {
  mobileCollapse: true,
}
