import React from 'react'

import { Svg } from './Svg'

interface Props {
  className?: string
}

export const LinkedIn: React.FunctionComponent<Props> = ({ className }) => (
  <Svg width={40} height={40} className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40
      8.95431 31.0457 0 20 0ZM9.60124 16.5651H14.1337V30.1832H9.60124V16.5651ZM14.4323
      12.3522C14.4029 11.017 13.448 10 11.8975 10C10.347 10 9.33333 11.017 9.33333 12.3522C9.33333
      13.6598 10.317 14.7061 11.8387 14.7061H11.8677C13.448 14.7061 14.4323 13.6598 14.4323
      12.3522ZM25.2609 16.2448C28.2435 16.2448 30.4795 18.1916 30.4795 22.3745L30.4793
      30.1827H25.947V22.897C25.947 21.067 25.2912 19.8182 23.6504 19.8182C22.3982 19.8182 21.6524
      20.6601 21.3248 21.4732C21.205 21.7646 21.1756 22.1706 21.1756 22.5776V30.183H16.6426C16.6426
      30.183 16.7023 17.8428 16.6426 16.5649H21.1756V18.4938C21.7771 17.5666 22.8545 16.2448 25.2609
      16.2448Z'
      fill='currentColor'
    />
  </Svg>
)
